<template>
<b-card no-body>
  <validation-observer
    v-slot="{ handleSubmit }" ref="VForm"
  >
    <b-form @submit.prevent="handleSubmit(doSubmitUser())">
      <b-card-header>
        <b-row>
          <b-col lg="8">
            <h5 class="card-title">{{ $parent.isAdd?'Tambah':'Edit' }} {{ $parent.pageTitle }}</h5>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col lg="12">
            <b-row>
              <b-col lg="6">
                <b-form-group>
                  <label>Nama Lengkap <span class="text-danger mr5">*</span></label>
                  <b-form-input type="text" class="form-control" v-model="row.bu_full_name" placeholder="e.g Nama Lengkap" />
                  <VValidate 
                    name="Nama Lengkap" 
                    v-model="row.bu_full_name" 
                    :rules="{required:1, min:2, max: 60}"
                  />
                </b-form-group>
              </b-col>
              
              <b-col lg="6">
                <b-form-group>
                  <label>Kode <span class="text-danger mr5">*</span></label>
                  <b-form-input :disabled="isAccountSetting" :formatter="$parent.code" type="text" class="form-control" v-model="row.bu_code" placeholder="e.g Kode" />
                  <VValidate 
                    name="Kode" 
                    v-model="row.bu_code" 
                    :rules="{regex:/^[^\s]+(\s+[^\s]+)*$/, required:1, min:2, max: 60}"
                  />
                </b-form-group>
              </b-col>

              <b-col lg="6">
                <b-form-group>
                  <label>NIK <span class="text-danger mr5">*</span></label>
                  <b-form-input :formatter="$parent.numberOnly" type="text" class="form-control" v-model="row.bu_nik" placeholder="e.g NIK" />
                  <VValidate 
                    name="NIK" 
                    v-model="row.bu_nik" 
                    :rules="{min:16, max: 20}"
                  />
                </b-form-group>
              </b-col>
              
              <b-col lg="6">
                <b-form-group>
                  <label>Level <span class="text-danger mr5">*</span></label>
                  <v-select :disabled="isAccountSetting" @input="changeLevel($event)" placeholder="Select Level" v-model="row.bu_level" :options="$parent.mrLevel" label="label" :reduce="v=>v.value"></v-select>
                  <VValidate 
                    name="User Role" 
                    v-model="row.bu_level" 
                    :rules="mrValidation.bu_level" 
                  />
                </b-form-group>
              </b-col>
              
              <b-col lg="6">
                <b-form-group>
                  <label>Poli <span class="text-danger mr5">*</span></label>
                  <v-select :disabled="row.bu_level !== 3 && row.bu_level !== 5 || isAccountSetting" placeholder="Select Poli" v-model="row.bu_mpo_id" :options="$parent.mPoli" label="text" :reduce="v=>v.value"></v-select>

                  <VValidate 
                    v-if="row.bu_level == 3 || row.bu_level == 5"
                    name="Poli" 
                    v-model="row.bu_mpo_id" 
                    :rules="{required : 1}"
                  />
                </b-form-group>
              </b-col>

              <b-col lg="6">
                <b-form-group>
                  <label>Alamat Email <span class="text-danger mr5">*</span></label>
                  <b-form-input :formatter="$parent.removeDoubleSpace" type="text" class="form-control" v-model="row.bu_email_address" placeholder="e.g Alamat Email" />
                  <VValidate 
                    name="Email" 
                    v-model="row.bu_email_address" 
                    :rules="mrValidation.bu_email_address" 
                  />
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group>
                  <label>Nomor Telepon</label>
                  <b-form-input :formatter="$parent.number" type="text" class="form-control" v-model="row.bu_phone" placeholder="e.g Nomor Telepon" />
                  <VValidate 
                    name="Nomor Telepon" 
                    v-model="row.bu_phone" 
                    :rules="{regex:/^[0-9\+]+$/}" 
                  />
                </b-form-group>
              </b-col>
              <b-col lg="6">
                <b-form-group>
                  <label>Username <span class="text-danger mr5">*</span></label>
                  <b-form-input :formatter="$parent.normalText" type="text" class="form-control" v-model="row.bu_username" placeholder="e.g Username" />
                  <VValidate 
                    name="Username" 
                    v-model="row.bu_username" 
                    :rules="mrValidation.bu_username" 
                  />
                </b-form-group>
              </b-col>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Kata Sandi <small class="txt_mandatory">*</small></label>
                  <div class="clearfix"></div>
                  <a data-toggle="collapse" href="javascript:;" @click="changePassword = true" role="button" aria-expanded="true" aria-controls="change_password" class="btn btn-light">
                  {{$parent.pageSlug !== 'add' || isAccountSetting ? 'Ubah' : 'Masukkan'}} Kata
                  Sandi </a>
                </div>
              </div>
              <div class="col-md-12 mb-2">
                <div class="row mt-2 collapse show" v-if="changePassword" id="change_password" style="">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group mb-3 mb-md-2">
                          <label>New Password <small class="txt_mandatory">*</small></label>
                          <div>
                            <input type="password" v-model="row.bu_password" class="form-control" placeholder="New Password">
                          </div>
                          <VValidate 
                            name="Password" 
                            v-model="row.bu_password" 
                            :rules="{required:!row.bu_id, min:5}"
                          />
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="form-group mb-3 mb-md-2">
                          <label>Confirm Password <small class="txt_mandatory">*</small></label>
                          <div>
                            <input type="password" v-model="row.c_bu_password" class="form-control" placeholder="Confirm Password">
                          </div>
                          
                          <VValidate 
                            name="Confirm Password" 
                            v-model="row.c_bu_password" 
                            :rules="{required:row.bu_password, min:5}"
                          />
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <b-col lg="6">
                <label>Unit <span class="text-danger mr5">*</span></label>
                <v-select :disabled="row.bu_level !== $parent.uFarmasi" placeholder="Select Unit" v-model="row.bu_unit_id" :options="$parent.mUnit" label="text" :reduce="v=>v.value"></v-select>

                <VValidate 
                  v-if="row.bu_level == $parent.uFarmasi"
                  name="Unit" 
                  v-model="row.bu_unit_id" 
                  :rules="{required : 1}" 
                />
              </b-col>

              
              <b-col lg="6">
                <label>BPJS ID <span class="text-danger mr5" v-if="row.bu_level == $parent.uDokter || row.bu_level == $parent.uDokterFisio">*</span></label>
                <b-form-input :formatter="$parent.normalText" type="text" class="form-control" v-model="row.bu_dokter_bpjs_id" placeholder="e.g BPJS ID" />                
                <VValidate 
                  v-if="row.bu_level == $parent.uDokter || row.bu_level == $parent.uDokterFisio"
                  name="BPJS ID" 
                  v-model="row.bu_dokter_bpjs_id" 
                  :rules="{required : 1}" 
                />
              </b-col>

              <b-col lg="6">
                <div class="file-uploader">
                  <label>Avatar</label>
                  <Uploader v-model="row.bu_pic" type="user"/>
                </div>
              </b-col>
              
              
              <b-col v-if="!isAccountSetting" lg="6">
                <b-form-group class="mt-3">
                  <label>Status <span class="text-danger mr5">*</span></label>
                  <b-form-radio-group
                    :options="Config.mr.StatusOptions"
                    v-model="row[$parent.statusKey]"
                  />
                  
                  <VValidate 
                    name="Status" 
                    v-model="row[$parent.statusKey]" 
                    :rules="{required:1}"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-body>
      <b-card-footer>
        <b-row>
          <b-col lg="12" class="text-right">
            <button @click="$router.back()" type="button" class="btn  btn-rounded  btn-default mr-3">Batal</button>
            <b-button type="submit" variant="primary" class="btn-rounded">Simpan</b-button>
          </b-col>
        </b-row>
      </b-card-footer>
    </b-form>
  </validation-observer>
</b-card>
</template>

<script>
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'

export default{
  props:{
    row:Object,
    mrValidation:Object,
    Config:Object,
    mrLevel:Array,
    isAccountSetting:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      changePassword : false
    }
  },
  methods:{
    changeLevel(e){
      if(e == 24){ 
        // ugd
        this.row.bu_mpo_id = 63
      }else{
        this.row.bu_mpo_id = null
      }
    },
    doSubmitUser(){
      if(!this.changePassword && this.$parent.isAdd){
        return this.$swal({
          title: "Tidak Dapat Lanjut",
          html: "Password Belum Diisi",                
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }
      
      if(this.changePassword && (this.row.c_bu_password !== this.row.bu_password)){
        return this.$swal({
          title: "Tidak Dapat Lanjut",
          html: "Password Tidak Match",                
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }

      return this.$parent.doSubmit(
          "/do/Users",
          _.assign({type:this.$parent.isAdd?'add':'update'}, {id: (this.$parent.pageId||this.$parent.pageSlug)}, _.clone(this.row)), 
          (type, resp)=>{
            if(type=='success'){
              if(this.isAccountSetting){
                location.reload()
              }else{
                this.$router.push({name:this.$route.name}).catch(()=>{})
              }
            }else{
              if(resp.response.status==422) {
                if((_.values(resp.response.data)[0][0]||"").indexOf('validation')>-1){
                  var msg = ""
                  $.each(Object.keys(resp.response.data),(i,v)=>{
                    msg = msg+" <br/>"+v+" : "+_.values(resp.response.data[v])
                  })
                  return this.$swal("Blocked With Validation!", msg)
                }else{
                  return this.$swal("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
              }else if(resp.response.status==400){

                return this.$swal({
                  title: resp.response.data.title||resp.response.data.message,
                  html: resp.response.data.title?resp.response.data.message:"",                
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            }
          },
          'POST', 'VForm', this.$refs
      )
    }
  }
}
</script>